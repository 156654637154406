import React from 'react'
import { Link } from 'gatsby'
import { Parallax } from '@components/layout'

import {
    item,
    item__button,
    item_button_desc,
} from '../styles/blogitem.module.scss'

const BlogCategoryItem = ({
    slug,
    name,
    description,
    featuredImage,
}) => {
    const long = name.length > 100 ? 300 : 200
    return (
        <div className={item}>
            {featuredImage ? (
                <Parallax height={long} image={featuredImage}>

				</Parallax>
            ):(null)}
            <Link to={`/blog/${slug}/`}>
                <button className={item__button}>{name}<i className='icofont-double-right' /></button>
                {description ? (
                <button className={item_button_desc}>{description}</button>
                ):(
                    null
                )}
            </Link>
        </div>
    )
}


export default BlogCategoryItem