import React from 'react'
import { Layout, Parallax } from '@components/layout'
import { Breadcrumbs } from '@components/shared'

import { ProductItem } from '@components/ecommerce'
import { PostItem } from '@components/blog'

import {
	page__wrapper,
	page__content,
} from './styles/page.module.scss'

const renderWithAllProps = (Comp) => (entry, key) => {
	return <Comp {...entry} key={key} />
}

const Tag = ({ pageContext }) => {
	const { page, tag, products, posts } = pageContext
	return (
		<Layout
			{...page}
		>
			<Parallax height={300} image={page.featuredImage}
				title={`${tag.name} - ${tag.count}`}
			>
			</Parallax>
			<div className={page__wrapper}>
					<Breadcrumbs
						elements={[
							{
								label: 'Tagi',
								url: `/tags/`,
							},
							{
								label: tag.name,
								url: `/tags/${tag.slug}/`,
							},
						]}
					/>
				<div className={page__content}>
				

					{products.map(renderWithAllProps(ProductItem))}
					{posts.map(renderWithAllProps(PostItem))}
				
				</div>
			</div>
		</Layout>
	)
}

export default Tag
